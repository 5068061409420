import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    leftGrid: { 
        marginTop: '15px',
        paddingRight: '30px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px'
        },
    },
    rightGrid: {
        borderLeft: '1px solid #E0E0E0',
        paddingLeft: '30px', 
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
            border: '0px',
            padding: '0px'
        },
    },
    buttonContainer: {
        // display: 'flex',
        // justifyContent: 'space-between'
    },
    bottonImg: {
        width: '30px',
        height: '30px',
        position: 'absolute',
        top: '3px',
        left: '5px'
    },
    btnMarginTP: {        
        marginTop: '2px',
    },
    contactHeading: {
        color: '#031D43',
        fontFamily: 'grifo-m-bold',
        fontSize: '20px',
        padding: '0px 0px 15px 0px'
    },
    reqStar: {
        fontSize: '14px',
        color: 'red'
    },
    reqInfo: {
        color: '#031D43',
        fontSize: '12px',
        verticalAlign: 'bottom',
        paddingRight: '7px',
        fontFamily: [
            'nunito-regular',
            'sans-serif'
          ].join(','),
    },
    serviceDetails: {
        color: '#031D43',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: 2,
        fontFamily: [
            'nunito-bold',
            'sans-serif'
          ].join(','),
    },
    serviceName: {
        paddingLeft: 10
    },
    mdGrid:{
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    smGrid:{
        paddingTop: '15px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    formContainer: {
        [theme.breakpoints.down('xs')]: {
            width: '98%',
        }
    }
}));