import React from 'react';
import { withRouter } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { PinkButtonWithIcon, WhiteButtonWithIcon } from '../../helpers/js/appButtons'
import ContactFormStyles from './ContactForm.theme'
import ContactInput from './../../components/UI/ContactInput/ContactInput'
import Box from '@material-ui/core/Box';
import { getQueryParamsString } from './../../helpers/js/getQueryParams';

export default withRouter((props) => {
    const classes = ContactFormStyles()
    const CFC = props.contactFormConfig;
    const appendingQueryParams = getQueryParamsString();

    // debugger
    const formLayout = {
        'leftColumn': ['fName', 'lName', 'email', 'phone', 'jobTitle', 'company'],
        'rightColumn': ['building', 'country', 'city', 'state', 'zip', 'subscribeNews', 'agreetoPrivacyPolicy']
    }
    const goBackHandler = () => {
        const { lang, country } = props.match.params;
        props.history.push(`/select-service/${lang}/${country}${appendingQueryParams}`);
    }

    const nonOtisCustomer = props.userSelectedService && props.services[props.userSelectedService] && props.maintenance === 'no';
    // var serviceInfo = null
    // if(nonOtisCustomer) {
    //     serviceInfo = (
    //         <Grid item xs={12} sm={12} my={0.5}>
    //             <div className={classes.serviceDetails}>
    //                 {props.labels._contact._recommendedService}:
    //                 <span className={classes.serviceName}>
    //                     {props.services[props.serviceRecommendationOrder[1]].name}
    //                 </span>
    //             </div>
    //             <div className={classes.serviceDetails}>
    //                 {props.labels._contact._selectedService}:
    //                 <span className={classes.serviceName}>
    //                     {props.services[props.userSelectedService].name}
    //                 </span>
    //             </div>
    //         </Grid>
    //     )
    // }
    return (
        <React.Fragment>
            <div className={classes.contactHeading}>
                {nonOtisCustomer ? props.labels._contact._contactMsgDefault : props.labels._contact._contactMsgWithSelection}
            </div>
            <form onSubmit={props.contactSubmitHandler}>
                <Grid container className={classes.formContainer}>
                    <Grid item xs={12} md={5} className={classes.leftGrid}>
                        <Grid container spacing={3}>
                            {/* <Grid item sm={12} className={classes.smGrid}><Box component="div" display={{ xs: 'block', sm: 'block', md: 'none' }}>{ serviceInfo }</Box></Grid> */}
                            {
                                formLayout.leftColumn.map((key) =>
                                    <ContactInput
                                        key={key}
                                        labels={props.labels}
                                        config={CFC.contactForm[key]}
                                        propName={key}
                                        country={props.country_}
                                        lang={props.lang}
                                        changed={(event) => props.contactInputChangedHandler(event, key)} />
                                )
                            }
                            {/* <Grid item className={classes.mdGrid}><Box component="div" display={{ xs: 'none', sm: 'none', md: 'block' }}>{ serviceInfo }</Box></Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7} className={classes.rightGrid}>
                        <Grid container spacing={3}>
                            {
                                formLayout.rightColumn.map((key) =>
                                    <ContactInput
                                        key={key}
                                        labels={props.labels}
                                        config={CFC.contactForm[key]}
                                        propName={key}
                                        country={props.country_}
                                        lang={props.lang}
                                        changed={(event) => props.contactInputChangedHandler(event, key)} />
                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} className={classes.buttonContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                {nonOtisCustomer ?
                                    <WhiteButtonWithIcon
                                        onClick={goBackHandler}
                                        className={classes.btnMarginTP}
                                        startIcon={<Avatar alt={props.labels._global._updateSelection}
                                            className={classes.bottonImg}
                                            src={require('./../../assets/images/o_config_up-arrow.svg')} />}>
                                        {props.labels._global._updateSelection}
                                    </WhiteButtonWithIcon>
                                    : null}
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                                <Box component="span" className={classes.reqInfo} display={{ xs: 'none', sm: 'inline-block' }}>
                                    <span className={classes.reqStar}>*</span>
                                    {props.labels._contact._requiredFields}
                                </Box>
                                <PinkButtonWithIcon className={classes.btnMarginTP} type="submit">
                                    {props.labels._global._submit}
                                </PinkButtonWithIcon>
                                <Box component="span" className={classes.reqInfo} display={{ xs: 'block', sm: 'none' }} align="left">
                                    <span className={classes.reqStar}>*</span>
                                    {props.labels._contact._requiredFields}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    );
})