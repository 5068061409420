import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const PinkButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#F65375',
    fontSize: 14,
    padding: '10px 20px',
    borderRadius: '0px',
    boxShadow: 'none',
    textTransform: 'none', 
    color: '#FFFFFF',  
    lineHeight: 'normal',
    fontFamily: [
      'nunito-regular',
      'sans-serif'
    ].join(','),
    '&:hover': {
      backgroundColor: '#F65375',
      borderColor: 'none',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'none',
      borderColor: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem #F65375',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
}))(Button);
  

export const WhiteButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    fontSize: 14,
    padding: '10px 20px',
    borderRadius: '0px',
    boxShadow: 'none',
    textTransform: 'none',
    borderColor: '#F65375',
    border: '1px solid #F65375',
    color: '#F65375',
    lineHeight: 'normal',
    fontFamily: [
      'nunito-regular',
      'sans-serif'
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
      border: '1px solid #F65375',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFFFFF',
      border: '1px solid #F65375',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem #F65375',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
}))(Button);

export const getStartStyles = makeStyles((theme) => ({
    container: {
      padding: '32px 10px 35px 10px',
      maxWidth: '575px',
      textAlign: 'left',
      marginRight: '0px',
      [theme.breakpoints.down('sm')]: {
        padding: '32px 20px',
        maxWidth: '100%'
      },
      [theme.breakpoints.down('xs')]: {
        padding: '32px 5px 35px 5px',
        maxWidth: '100%'
      },
    },
    h3: {
      fontFamily: 'grifo-m-bold',
      fontSize: '40px',
      fontWeight: 'bold',
      marginTop: '0',
      marginBottom: '14px',
      color: '#031D43',
      textAlign:'left',
      [theme.breakpoints.down('xs')]: {
        fontSize: '27px'
      }
    },
    h3Sandstone: {
      background: '#cba051',
      height: '3px',
      width: '45px',
      display: 'block',
      marginBottom: '20px'
    },
    h5Bold: {
      fontFamily: [
        'nunito-bold',
        'sans-serif'
      ].join(','),
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#031D43',
      lineHeight: '21.6px',
      marginBottom: '20px !important',
      marginTop: '0px'       
    },
    h5: {
      marginBottom: '5px !important',
      fontFamily: [
        'nunito-regular',
        'sans-serif'
      ].join(','),
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#031D43',
      marginTop: '140px',
      [theme.breakpoints.down('md')]: {
        marginTop: '80px'
      },
    }
}));
  