import React from "react";
import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";

import RowElement from "./RowElement/RowElement";
import { tableViewStyles } from "./TableView.theme";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getQueryParamsString } from './../../../helpers/js/getQueryParams';

import {
  WhiteButtonWithIcon,
  GreyButtonWithIcon,
  TransparentButton
} from "./../../../helpers/js/appButtons";
import "./TableView.css";

export default withRouter(props => {
  const classes = tableViewStyles();
  const goBackHandler = () => {
    const appendingQueryParams = getQueryParamsString();
    const { lang, country } = props.match.params;
    props.history.push(`/find-service/${lang}/${country}${appendingQueryParams}`);
  };
  const downloadBrochure = pdfUrl => {
    // const d =
    //   "https://assets.otiscreate.com/iaastorage/ProductDirectory/Translation/Brochure/CL_1/P_20/Gen2_Brochure_EN.pdf";
    window.open(pdfUrl);
  };
  const selectServiceHandler = selected => {
    //alert('Service Selected --> '+ selected)
    // Set Gobal State
    props.serviceOnSelected(selected);
  };
  const serviceAttr = props.labels._services._serviceAttributes;
  const sro = props.serviceRecommendationOrder;
  return (
    <React.Fragment>
      <Container className="recommednation_container" style={{ padding: 0 }}>
        <Typography variant="h2" classes={{ root: classes.h2 }}>
          {props.labels._services._recommendedServiceTitle}
        </Typography>
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead className={classes.tHead}>
              <RowElement
                optionTitle=""
                serviceRecommendation={sro[1]}
                serviceOrderOne={props.services["s_1"].displayText}
                serviceOrderTwo={props.services["s_2"].displayText}
                serviceOrderThree={props.services["s_3"].displayText}
              />
            </TableHead>
            <TableBody>
              {Object.keys(serviceAttr).map((key, idx) => (
                <RowElement
                  key={idx}
                  optionTitle={serviceAttr[key]}
                  serviceRecommendation={sro[1]}
                  serviceOrderOne={props.services["s_1"].attributes[key]}
                  serviceOrderTwo={props.services["s_2"].attributes[key]}
                  serviceOrderThree={props.services["s_3"].attributes[key]}
                />
              ))}
              {props.services["s_1"].brochureURL ||
                props.services["s_2"].brochureURL ||
                props.services["s_3"].brochureURL ? (
                  <RowElement
                    align="left"
                    serviceRecommendation={sro[1]}
                    optionTitle={props.labels._services._brochure}
                    serviceOrderOne={
                      props.services["s_1"].brochureURL ? (
                        <TransparentButton
                          onClick={() =>
                            downloadBrochure(props.services["s_1"].brochureURL)
                          }
                        >
                          {props.labels._services._downloadBrochure}
                        </TransparentButton>
                      ) : (
                          ""
                        )
                    }
                    serviceOrderTwo={
                      props.services["s_2"].brochureURL ? (
                        <TransparentButton
                          onClick={() =>
                            downloadBrochure(props.services["s_2"].brochureURL)
                          }
                        >
                          {props.labels._services._downloadBrochure}
                        </TransparentButton>
                      ) : (
                          ""
                        )
                    }
                    serviceOrderThree={
                      props.services["s_3"].brochureURL ? (
                        <TransparentButton
                          onClick={() =>
                            downloadBrochure(props.services["s_3"].brochureURL)
                          }
                        >
                          {props.labels._services._downloadBrochure}
                        </TransparentButton>
                      ) : (
                          ""
                        )
                    }
                  />
                ) : (
                  ""
                )}
              {props.labels._services._serviceNote.trim() && (
                <TableRow>
                  <TableCell
                    colSpan="6"
                    classes={{ root: classes.tCellNoBackground }}
                  >
                    <Typography variant="h6" classes={{ root: classes.h6 }}>
                      {props.labels._services._serviceNote}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              <RowElement
                align="center"
                optionTitle={
                  <WhiteButtonWithIcon
                    onClick={goBackHandler}
                    startIcon={
                      <Avatar
                        alt={props.labels._global._updateSelection}
                        className={classes.bottonImg}
                        src={require("./../../../assets/images/o_config_up-arrow.svg")}
                      />
                    }
                  >
                    {props.labels._global._updateSelection}
                  </WhiteButtonWithIcon>
                }
                serviceOrderOne={
                  <GreyButtonWithIcon
                    onClick={() => selectServiceHandler("s_1")}
                    style={
                      sro[1] === "s_1" ? { backgroundColor: "#F65375" } : {}
                    }
                    startIcon={
                      <Avatar
                        alt={props.labels._global._select}
                        className={classes.bottonImg}
                        src={require("./../../../assets/images/o_config_down-arrow.svg")}
                      />
                    }
                  >
                    {props.labels._global._select}
                  </GreyButtonWithIcon>
                }
                serviceOrderTwo={
                  <GreyButtonWithIcon
                    onClick={() => selectServiceHandler("s_2")}
                    style={
                      sro[1] === "s_2" ? { backgroundColor: "#F65375" } : {}
                    }
                    startIcon={
                      <Avatar
                        alt={props.labels._global._select}
                        className={classes.bottonImg}
                        src={require("./../../../assets/images/o_config_down-arrow.svg")}
                      />
                    }
                  >
                    {props.labels._global._select}
                  </GreyButtonWithIcon>
                }
                serviceOrderThree={
                  <GreyButtonWithIcon
                    onClick={() => selectServiceHandler("s_3")}
                    style={
                      sro[1] === "s_3" ? { backgroundColor: "#F65375" } : {}
                    }
                    startIcon={
                      <Avatar
                        alt={props.labels._global._select}
                        className={classes.bottonImg}
                        src={require("./../../../assets/images/o_config_down-arrow.svg")}
                      />
                    }
                  >
                    {props.labels._global._select}
                  </GreyButtonWithIcon>
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>
  );
});
