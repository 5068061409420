import React from 'react';
import { useParams, useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import TableView from './../../components/ServiceRecommendation/TableView/TableView'
import CarouselView from './../../components/ServiceRecommendation/CarouselView/CarouselView'
import FilterResultsTheme from './FilterResults.theme'
import { useMediaQuery } from "react-responsive";
import { getQueryParamsString } from './../../helpers/js/getQueryParams';

export default (props) => {
    const styl = FilterResultsTheme();
    const { country, lang } = useParams();
    let history = useHistory();
    const appendingQueryParams = getQueryParamsString();

    if (props.serviceRecommendationOrder[1] === "") {
        history.push(`/find-service/${lang}/${country}${appendingQueryParams}`);
    }
    const selectedServiceHandler = (serviceId) => {
        props.onSelected(serviceId)
        history.push(`/contact-us/${lang}/${country}${appendingQueryParams}`);
    }

    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)"
    });

    return (
        <React.Fragment>
            {
                props.appLoader === 'off' ?
                    <Container className={styl.container}>
                        {(isTabletOrMobileDevice) ?
                            <CarouselView services={props.services} labels={props.labels}
                                serviceRecommendationOrder={props.serviceRecommendationOrder}
                                serviceOnSelected={selectedServiceHandler} />
                            :
                            <TableView services={props.services} labels={props.labels}
                                serviceRecommendationOrder={props.serviceRecommendationOrder}
                                serviceOnSelected={selectedServiceHandler} />
                        }

                    </Container>
                    :
                    <LinearProgress />
            }
        </React.Fragment>
    );
}