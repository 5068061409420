import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';

import GetStarted from '../../components/GetStarted/GetStarted';
import GetStartedBanner from '../../components/GetStartedBanner/GetStartedBanner';
import homeTheme from './Home.theme'

export default (props) => {
    const styl = homeTheme();
    return (
        <React.Fragment>
            {
                props.appLoader === 'off' ?
                    <Container classes={{root: styl.homeContainer}}>        
                        <Grid container direction="row-reverse" justify="center" alignItems="flex-start">
                            <GetStartedBanner />
                            <GetStarted {...props} />
                        </Grid>
                    </Container>
                :
                    <LinearProgress />
            }
        </React.Fragment>
    )
}