import React from 'react';
import { Link, useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { PinkButton, WhiteButton, getStartStyles } from './GetStarted.theme';
import { getQueryParamsString } from './../../helpers/js/getQueryParams';

export default (props) => {
    const classes = getStartStyles();
    const { country, lang } = useParams();
    const appendingQueryParams = getQueryParamsString();
    // console.log('Get Started!', props)
    return (
        <React.Fragment>
            <Grid item md={7} xs={12}>
                <Container classes={{ root: classes.container }}>
                    <Typography variant="h3" classes={{ root: classes.h3 }}>
                        {props.labels._home._homeCardTitle}
                    </Typography>
                    <Box classes={{ root: classes.h3Sandstone }}></Box>
                    <Typography variant="h5" classes={{ root: classes.h5Bold }}>
                        {props.labels._home._homeCardDesc}
                    </Typography>
                    <Link to={`/find-service/${lang}/${country}${appendingQueryParams}`}>
                        <PinkButton>{props.labels._global._getStarted}</PinkButton>
                    </Link>
                    <Typography variant="h5" classes={{ root: classes.h5 }}>
                        {props.labels._home._homeAlreadyCustomer}
                    </Typography>
                    <Link to={`/contact-us/otis-customer/${lang}/${country}${appendingQueryParams}`}>
                        <WhiteButton>{props.labels._global._contactUs}</WhiteButton>
                    </Link>
                </Container>
            </Grid>
        </React.Fragment>
    )
}