import React from 'react';
import Slider from "@material-ui/core/Slider";
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';

import sliderInputStyles from './SliderInput.theme'
import './SliderInput.css';

const sliderInput = ( props ) => {    
    const config = props.config;
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>', config)
    const sliderOpt = config.optionList.map((item, i) => {
        return {
            label: item.displayText,
            value: i
        }
    });
    const getActualValue = (event, val) => {
        // Sending the index of the options array
        const index = config.optionList.findIndex(x => x.id === config.optionList[val].id);
        props.changed(index);
    } 
    
    const styles = sliderInputStyles();
    return (
        <Box component="div" classes={{root: styles.inputBox}}>
            <InputLabel classes={{root: styles.label}} htmlFor="">
                <Box component="span" classes={{root: styles.qnNumber}}>{props.order}.</Box>
                {config.displayText} <Box component="span" classes={{root: styles.reqStar}}>*</Box>
            </InputLabel>
            <Box classes={{ root: styles.sliderContainer }}>
                <Slider
                    classes={{
                    track: styles.customTrack,
                    rail: styles.customRail,
                    thumb: "Custom-thumb",
                    mark: styles.customMark,
                    markLabel: styles.muiSliderMarkLabel
                    }}                    
                    value={parseInt(config.value) > 0 ? parseInt(config.value) : 0}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="off"
                    step={1}
                    marks={sliderOpt}
                    min={0}
                    max={config.optionList.length-1}
                    onChange={getActualValue}
                />
            </Box>
        </Box>
    );

};

export default sliderInput;