import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    leftGrid: {
        borderRight: '1px solid #E0E0E0', 
        paddingTop: '15px',
        [theme.breakpoints.down('sm')]: {
            border: '0px'
        },
    },
    rightGrid: {
        paddingLeft: '30px', 
        paddingTop: '15px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px'
        },
    },
    buttonContainer: {
        textAlign: 'center'
    },
    bottonImg: {
        width: '30px',
        height: '30px',
        position: 'absolute',
        top: '3px',
        left: '5px'
    },
    btnMarginTP: {        
        marginTop: '15px'
    },
    btnPadding:{
        paddingRight: '25px',
        [theme.breakpoints.down('xs')]: {
            paddingRight: '0px'
        },
    }
}));