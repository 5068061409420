const vadidateForm = (value, rules) => {
    //console.log(value, rules);
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        if(typeof(value) === 'number') {
            isValid = true;
        } else {
            isValid = value.trim() !== '' && isValid;
        }      
    }
    // console.log(value.trim(), value)

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    
    if(rules.checked){
        isValid = (value === "yes") && isValid
    }

    return isValid;
}

export default vadidateForm;