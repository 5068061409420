import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: '#EBECEE',
    },
    tooltip: {
        color: '#000',
        backgroundColor: '#EBECEE',
        padding: 10,
        borderRadius: 0,
        fontFamily: [
            'nunito-regular',
            'sans-serif'
          ].join(',')
    },
}));


export default (props) => {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}