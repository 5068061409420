const getContactInput = (mnt, cf, p, countryLabel) => {
    // console.log('contactInput', mnt, cf, p)
    let privacyPolicy = (cf.agreetoPrivacyPolicy.value === "yes") ? true : false
    const inputData = {
        firstName: cf.fName.value,
        lastName: cf.lName.value,
        emailAddress: cf.email.value,
        telephone: cf.phone.value,
        company: cf.company.value,        
        buildingAddress: cf.building.value,
        city: cf.city.value,
        state: cf.state.value,
        buildingZip: cf.zip.value,
        jobTitle: p.labels._contact[cf.jobTitle.value],
        subscribe: cf.subscribeNews.value,
        maintenance: mnt,
      //  country: p.country,
        country: countryLabel,
        elevatorType:  (mnt === 'no') ? p.properties.p_32.optionList[p.properties.p_32.value].displayText + ' - ' + p.properties.p_33.optionList[p.properties.p_33.value].displayText : null,  
        frequencyOfUse:  (mnt === 'no') ? p.properties.p_9.optionList[p.properties.p_9.value].displayText : null,
        noOfElevators:  (mnt === 'no') ? p.properties.p_3.value : 0,
        noOfFloors:  (mnt === 'no') ? p.properties.p_2.value : 0,
        manufacturer: (mnt === 'no') ? p.properties.p_34.optionList[p.properties.p_34.value].displayText : null,
        ageofunit: (mnt === 'no') ? p.properties.p_35.optionList[p.properties.p_35.value].displayText : null,
        vertical:  (mnt === 'no') ? p.properties.p_1.optionList[p.properties.p_1.value].displayText : null,
        svcLevel:  (mnt === 'no') ? p.services[p.userSelectedService].displayText : null,
        svcLevelRecommended: (mnt === 'no') ? p.services[p.serviceRecommendationOrder[1]].displayText : null,
        countryLanguageCode: p.lang +'-'+ p.country_,
        appUrl: p.appUrl.href,
        AgreetoPrivacyPolicy: privacyPolicy
    }
    return inputData
}


export default getContactInput