import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import ContactForm from './../../components/ContactForm/ContactForm'
import ContactFormConfig from './../../helpers/json/contactFormConfig.json'
import CheckValidity from './../../helpers/js/formValidation'
import appBodyTheme from './../../helpers/themes/appBody.theme'
import getContactInput from './../../helpers/js/contact.inputs'
import { getUtmUrlQueryParamsIfExist, getQueryParamsString } from './../../helpers/js/getQueryParams';

class Contact extends Component {
    constructor(props) {
        super(props);

        if (props.contactUs && props.contactUs.country) {
            ContactFormConfig.contactForm.country.elementConfig = {
                options: props.contactUs.country
            }

            //Setting default country selected
            let countryData = this.getCountryDetail(props.country_);
            if (countryData[0]) {
                ContactFormConfig.contactForm.country.value = countryData[0].name;
            }

            if (countryData[0] && countryData[0].state && countryData[0].state[0]) {
                ContactFormConfig.contactForm.state.elementType = 'dropdownAPI';
                ContactFormConfig.contactForm.state.elementConfig = {
                    options: countryData[0].state
                }
                ContactFormConfig.contactForm.state.value = countryData[0].state[0].name;
                ContactFormConfig.contactForm.state.valid = true;
                ContactFormConfig.contactForm.state.validation.required = true;
                ContactFormConfig.contactForm.state.touched = false;

            } else {
                ContactFormConfig.contactForm.state.displayTextKey = "_state";
                ContactFormConfig.contactForm.state.elementType = "input";
                ContactFormConfig.contactForm.state.elementConfig.type = "text";
                ContactFormConfig.contactForm.state.elementConfig.placeholder = "";
                ContactFormConfig.contactForm.state.value = '';
                ContactFormConfig.contactForm.state.validation.required = true;
                ContactFormConfig.contactForm.state.valid = false;
                ContactFormConfig.contactForm.state.touched = false;

            }
        }
        this.state = { cfc: ContactFormConfig, apiLoader: false }
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.maintenance && prevState.maintenance !== 'help' && nextProps.match.params.type === 'help') {
            return { maintenance: 'help' }
        }
        return null
    }

    componentDidMount() {
        const { type } = this.props.match.params
        var maintenance;
        if (type === 'help') {
            maintenance = 'help'
        } else if (type === 'otis-customer') {
            maintenance = 'yes'
        } else {
            if (this.props.userSelectedService === "" && this.props.appLoader === "off") {
                const appendingQueryParams = getQueryParamsString();
                this.props.history.push(`/find-service/${this.props.params}${appendingQueryParams}`);
            }
            maintenance = 'no'
        }
        this.setState({ maintenance: maintenance })
    }

    getCountryDetail = (input) => {
        let countryDetail = {}
        if (this.props.contactUs.country) {
            countryDetail = this.props.contactUs.country.filter((data) => data.name.toUpperCase() === input.toUpperCase());
        }
        return countryDetail;
    }

    contactInputChangedHandler = (event, key) => {
        const updatedContactForm = {
            ...this.state.cfc.contactForm
        };
        const updatedFormElement = {
            ...updatedContactForm[key]
        };
        if (updatedFormElement.elementType === 'checkbox' || updatedFormElement.elementType === 'checkboxPolicy') {
            updatedFormElement.value = event.target.checked ? 'yes' : 'no'
        } else {
            updatedFormElement.value = event.target.value;
            if (key === 'country') {
                let countryInfo = this.getCountryDetail(event.target.value);
                let state = countryInfo[0].state;
                if (state[0] && state[0].name) {
                    ContactFormConfig.contactForm.state.displayTextKey = "_state";
                    ContactFormConfig.contactForm.state.elementType = "dropdownAPI";
                    ContactFormConfig.contactForm.state.elementConfig = {
                        options: state
                    }
                    ContactFormConfig.contactForm.state.value = state[0].name;
                    ContactFormConfig.contactForm.state.validation.required = true;
                    ContactFormConfig.contactForm.state.valid = true;
                    ContactFormConfig.contactForm.state.touched = false;

                    updatedContactForm.state.elementType = "dropdownAPI";
                    delete updatedContactForm.state.elementConfig.type;
                    delete updatedContactForm.state.elementConfig.placeholder;
                    updatedContactForm.state.elementConfig = {
                        options: state
                    }
                    updatedContactForm.state.value = state[0].name;
                    updatedContactForm.state.validation.required = true;
                    updatedContactForm.state.valid = true;
                    updatedContactForm.state.touched = false;

                } else {
                    ContactFormConfig.contactForm.state.displayTextKey = "_state";
                    ContactFormConfig.contactForm.state.elementType = "input";
                    ContactFormConfig.contactForm.state.elementConfig.type = "text";
                    ContactFormConfig.contactForm.state.elementConfig.placeholder = "";
                    ContactFormConfig.contactForm.state.value = '';
                    ContactFormConfig.contactForm.state.validation.required = true;
                    ContactFormConfig.contactForm.state.valid = false;
                    ContactFormConfig.contactForm.state.touched = false;

                    delete updatedContactForm.state.elementConfig.options;
                    updatedContactForm.state.elementType = "input";
                    updatedContactForm.state.elementConfig.type = "text";
                    updatedContactForm.state.elementConfig.placeholder = "";
                    updatedContactForm.state.value = '';
                    updatedContactForm.state.validation.required = true;
                    updatedContactForm.state.valid = false;
                    updatedContactForm.state.touched = false;
                }
            }
        }
        updatedFormElement.valid = CheckValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedContactForm[key] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedContactForm) {
            formIsValid = updatedContactForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ cfc: { contactForm: updatedContactForm, formIsValid: formIsValid } });
    }

    contactSubmitHandler = (event) => {
        event.preventDefault();
        const submittedContactForm = {
            ...this.state.cfc.contactForm
        };
        const formData = {};
        for (let key in submittedContactForm) {
            formData[key] = submittedContactForm[key].value;
            submittedContactForm[key].touched = true;
        }
        if (this.state.cfc.formIsValid) {
            this.setState({ apiLoader: true });

            //Changing country name to country label
            let selectedCountry = this.getCountryDetail(this.state.cfc.contactForm.country.value);
            let countryLabel = this.state.cfc.contactForm.country.value;
            if (selectedCountry[0] && selectedCountry[0].label) {
                countryLabel = selectedCountry[0].label;
            }

            const CI = { ...getContactInput(this.state.maintenance, this.state.cfc.contactForm, this.props, countryLabel), ...getUtmUrlQueryParamsIfExist() };

            //hubspot changes for Role (Dec 2021)
            CI.jobTitle = submittedContactForm['jobTitle'].value;
            
            //hubspot Tracking
            CI.hutk = this.getCookie('hubspotutk');
            CI.pageUri = this.props.appUrl.href;
            CI.pageName = "Contact Us";

            //console.log('contactInput', CI)
            axios.post(`${process.env.REACT_APP_API_URL}/contactUs`, CI)
                .then(res => {
                    // console.log(res);
                    const appendingQueryParams = getQueryParamsString();
                    this.setState({ apiLoader: false });
                    this.props.history.push(`/thank-you/${this.props.params}${appendingQueryParams}`);
                })
                .catch(err => {
                    this.setState({ apiLoader: false });
                    this.props.history.push(`/Error`);
                })
            //this.props.history.push(`/thank-you/${this.props.params}`);          
        } else {
            this.setState({ cfc: { contactForm: submittedContactForm } });
        }

        //console.log("Contact Form Final Output", this.cfc.updatedContactForm, this.props.properties, this.props.services, this.props.serviceRecommendationOrder)
    }

    componentWillUnmount() {
        const forReset = { ...this.state.cfc.contactForm };
        for (let key in forReset) {
            forReset[key].touched = false;
        }
        this.setState({ cfc: { contactForm: forReset } });
    }

    render() {
        const { classes } = this.props;
        const apiProgress = this.state.apiLoader ? <LinearProgress className={classes.globalLoader} /> : null;
        return (
            <React.Fragment>
                {apiProgress}
                {
                    this.props.appLoader === 'off' ?
                        <Container className={classes.container} >
                            <ContactForm {...this.props}
                                contactFormConfig={this.state.cfc}
                                maintenance={this.state.maintenance}
                                contactSubmitHandler={this.contactSubmitHandler}
                                contactInputChangedHandler={this.contactInputChangedHandler} />
                        </Container>
                        :
                        <LinearProgress />
                }
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(appBodyTheme, { withTheme: true })(Contact))