import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';

import getStartBannerStyles from './GetStartedBanner.theme'

const BannerDesk = require('../../assets/images/o_configurator_desktop.jpg');
const BannerTab = require('../../assets/images/o_configurator_tablet.jpg');
const BannerMob = require('../../assets/images/o_configurator_mobile.jpg');

export default (props) => {
    const classes = getStartBannerStyles();
    return (
        <React.Fragment>
            <Grid item md={5} xs={12}>
                <CardMedia classes={{root: classes.desk}} component="img" image={BannerDesk} title="OTIS SIGNATURE SERVICE" />
                <CardMedia classes={{root: classes.tab}} component="img" image={BannerTab} title="OTIS SIGNATURE SERVICE" />
                <CardMedia classes={{root: classes.mob}} component="img" image={BannerMob} title="OTIS SIGNATURE SERVICE" />
            </Grid>
        </React.Fragment>
    )
}