import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { PinkButtonWithIcon, WhiteButtonWithIcon } from '../../helpers/js/appButtons'
import filterFormStyles from './FilterForm.theme'
import SliderInput from './../../components/UI/SliderInput/SliderInput'
import FilterInput from './../../components/UI/FilterInput/FilterInput'
import ToolTip from '../../components/UI/Tooltip/Tooltips'

export default (props) => {
    const classes = filterFormStyles()
    // console.log('>>>>>>>>>>>>>>', props)
    return (
        <React.Fragment>
            <form onSubmit={props.filterSubmitHandler}>
                <Grid container>
                    <Grid item md={6} className={classes.leftGrid}>
                        <FilterInput
                            key='p_1'
                            order={1}
                            general={props.general}
                            config={props.filterForm['p_1']}
                            invalid={!props.filterForm['p_1'].valid}
                            changed={(event) => props.filterArrayInputChangedHandler(event, 'p_1')} />
                        <FilterInput
                            key='p_34'
                            order={2}
                            config={props.filterForm['p_34']}
                            invalid={!props.filterForm['p_34'].valid}
                            changed={(event) => props.filterInputChangedHandler(event, 'p_34')} />
                        <SliderInput
                            config={props.filterForm['p_35']}
                            order={3}
                            changed={(event) => props.filterArrayInputChangedHandler(event, 'p_35')} />
                        <FilterInput
                            key='p_32'
                            order={4}
                            general={props.general}
                            config={props.filterForm['p_32']}
                            invalid={!props.filterForm['p_32'].valid}
                            changed={(event) => props.filterArrayInputChangedHandler(event, 'p_32')} />
                    </Grid>
                    <Grid item md={6} className={classes.rightGrid}>
                        <FilterInput
                            key='p_33'
                            order={5}
                            general={props.general}
                            config={props.filterForm['p_33']}
                            invalid={!props.filterForm['p_33'].valid}
                            changed={(event) => props.filterArrayInputChangedHandler(event, 'p_33')} />
                        <FilterInput
                            key='p_3'
                            order={6}
                            config={props.filterForm['p_3']}
                            invalid={!props.filterForm['p_3'].valid}
                            changed={(event) => props.filterInputChangedHandler(event, 'p_3')} />
                        <FilterInput
                            key='p_2'
                            order={7}
                            config={props.filterForm['p_2']}
                            invalid={!props.filterForm['p_2'].valid}
                            changed={(event) => props.filterInputChangedHandler(event, 'p_2')} />
                        <SliderInput
                            config={props.filterForm['p_9']}
                            order={8}
                            changed={(event) => props.filterArrayInputChangedHandler(event, 'p_9')}
                        />
                        
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.buttonContainer}>
                        <span className={classes.btnPadding}>
                            <WhiteButtonWithIcon className={classes.btnMarginTP} onClick={props.resetFilter}
                                startIcon={<Avatar className={classes.bottonImg} alt="Reset" src={require('./../../assets/images/o_config_refresh-arrow.svg')} />}>
                                {props.labels._global._reset}
                            </WhiteButtonWithIcon>
                        </span>
                        <ToolTip interactive={true} title={(!props.formIsValid) ? props.labels._filter._validationErr : ''} placement="top-end">
                            <PinkButtonWithIcon className={classes.btnMarginTP} type="submit"
                                startIcon={<Avatar alt="Filter" className={classes.bottonImg}
                                    src={require('./../../assets/images/o_config_down-arrow.svg')} />}>
                                {props.labels._filter._filterFindService}
                            </PinkButtonWithIcon>
                        </ToolTip>
                        {/* <Button btnType="Success" disabled={!props.formIsValid}>Submit</Button> */}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    );
}