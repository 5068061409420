import { makeStyles } from '@material-ui/core/styles';

const sliderInputStyles = makeStyles((theme) => ({
    inputBox: {
        padding: '5px 0px 20px 0px', 
        width: '100%',
        float: 'left'
    },
    label: {
        color: '#031D43',
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: [
            'nunito-bold',
            'sans-serif'
          ].join(','),
    },
    sliderContainer: {
      width: '60%', 
      paddingTop: '12px',
      marginLeft: '35px',
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        marginLeft: '15px'
      }
    },
    muiSliderMarkLabel: {
      top: '26px',
      color: '#031D43',
      position: 'absolute',
      transform: 'translateX(-50%)',
      fontSize: '12px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      fontFamily: [
        'nunito-bold',
        'sans-serif'
      ].join(','),
      paddingTop: '10px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '12px',
        transform: 'translateX(-12%)',
        width: '25%',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word'
      },
      [theme.breakpoints.down('xs')]: {
        transform: 'translateX(-20%)',
        width: '25%',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word'
      }
    },
    customTrack: {
      backgroundColor: '#e3d0af',
      height: '5px',
      borderRadius: 0
    },
    customRail: {
      backgroundColor: '#979797',
      opacity: 'unset',
      height: '5px',
      borderRadius: '2px'
    },
    customThumb: {
      backgroundImage: `url(${"./../../../assets/images/handle.png"})`,
      width: '24px',
      height: '24px',
      backgroundSize: '24px',
      backgroundColor: 'transparent',
      marginTop: '-9px',
      marginLeft: '-10px'
    },
    customMark: {
      height: '5px',
      width: '3px',
      backgroundColor: 'white',
      borderRadius: '0'
    },
    qnNumber: {
      marginRight: '7px'
    },
    reqStar: {
      color: 'red'
    }
}));

export default sliderInputStyles