import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { getQueryParamsString } from './../../helpers/js/getQueryParams';

import { BlueButton, headerStyles } from './Header.theme'

const logo = require('../../assets/images/OTIS SIGNATURE SERVICE LOCKUP-01.svg');

export default (props) => {
    // console.log('Header Props', props)
    const classes = headerStyles();
    const img = <img alt="OTIS SIGNATURE SERVICE" className={classes.iconCss} src={logo} />
    const appendingQueryParams = getQueryParamsString();

    return (
        <Fragment>
            <AppBar classes={{ root: classes.root }}>
                <Container classes={{ root: classes.conRoot }}>
                    <Toolbar classes={{ root: classes.toolBar }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item className={classes.logoImg}><Link to={`/${props.params}${appendingQueryParams}`}>{img}</Link></Grid>
                            <Grid item>
                                <Link to={`/contact-us/help/${props.params}${appendingQueryParams}`}>
                                    {(props.labels._needHelp) ? <BlueButton>{props.labels._needHelp}</BlueButton> : null}
                                </Link>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="div" classes={{ root: classes.offsetCss }} display={{ xs: 'block', sm: 'none' }}></Box>
        </Fragment>
    )
};