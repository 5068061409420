function getQueryParamsString() {
  return (window.location.search && window.location.search.length) ? window.location.search : '';
}

function getUtmUrlQueryParamsIfExist() {
  const utmParamsOutput = {};
  const utmParamNames = ['utm_source', 'utm_campaign', 'utm_content', 'utm_medium', 'utm_term'];
  if (window.location.search && window.location.search.length) {
    const urlParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      if (utmParamNames.includes(key)) {
        utmParamsOutput[key] = value;
      }
    }
  }
  return utmParamsOutput;
}

export { getQueryParamsString, getUtmUrlQueryParamsIfExist };
