import { makeStyles } from "@material-ui/core/styles";

export const tableViewStyles = makeStyles(theme => ({
  h2: {
    fontFamily: "grifo-m-bold",
    fontWeight: "bold",
    fontSize: "20px",
    marginTop: "0",
    marginBottom: "10px",
    color: "#031D43"
  },
  h6: {
    background: "none",
    fontFamily: "nunito-bold",
    fontSize: "12px",
    fontWeight: "normal",
    color: "#031d43"
  },
  tCellNoBackground: {
    verticalAlign: "top",
    padding: "10px 15px !important",
    background: "none"
  },
  bottonImg: {
    width: "30px",
    height: "30px",
    position: "absolute",
    top: "3px",
    left: "5px"
  },
  tHead: {
    // '& $tr': {
    //     background: '#FAFAFA !important',
    //     border: 0
    // },
    // '& $th': {
    //     fontFamily: 'grifo-m-bold !important',
    //     fontSize: '18px !important',
    //     color: '#fff !important',
    //     textAlign: 'center !important',
    //     padding: '0 20px !important',
    //     margin: '0 auto !important',
    //     height: '50px !important',
    //     verticalAlign: 'middle'
    // }
  }
}));
