import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container:{
    textAlign: 'center',
    marginTop: '50px'
  },
  h3: {
    fontFamily: 'grifo-m-bold',
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: '35.6px',
    marginTop: '0',
    marginBottom: '20px',
    color: '#031D43'
  },
  h5Bold: {
    font: 'normal 16px nunito-bold',
    color: '#031D43',
    lineHeight: '21.6px',
    marginBottom: '20px !important',
    marginTop: '0px'       
  }
}));
  