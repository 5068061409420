import React from 'react';

import Header from '../../components/Header/Header';

const layout = (props) => (
        <React.Fragment>
            <Header labels={props.labels} params={props.params} />
            <main>
                {props.children}
            </main>
        </React.Fragment>
    );

export default layout;