import React from 'react';
import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';

import './FilterInput.css';

import { filterInputStyles, InputTextbox, ContactInputTxt } from './FilterInput.theme'

const filterInput = (props) => {
    // console.log("props", props)
    const classes = {};
    const styles = filterInputStyles();
    let inputElement = null;
    const inputClasses = [classes.InputElement];
    const config = props.config;
    if (config.invalid && config.validation && config.touched) {
        inputClasses.push(classes.Invalid);
    }
    let validationError = null;
    if (props.invalid && config.touched) {
        validationError = styles.valErr;
    }

    switch (config.propertyType) {
        case ('TextBox'):
            inputElement = <InputTextbox
                classes={{ root: styles.textBoxSM, input: styles.textBoxSMinput }}
                value={config.value}
                type="number"
                inputProps={{ min: 1, step: 1, autoComplete: 'off' }}
                onChange={props.changed} />
            break;
        case ('Dropdown'):
            inputElement = config.optionList.map((option, i) => {
                let thumbnailImgBox = styles.thumbnailImgBox;
                let thumbnailLabel = styles.thumbnailLabel;
                if (parseInt(config.value) === i) {
                    thumbnailImgBox = thumbnailImgBox + ' ' + styles.thumbnailImgBoxSelected;
                    thumbnailLabel = thumbnailLabel + ' ' + styles.thumbnailLabelSelected;
                }
                return (
                    <Grid item key={i}>
                        <Box onClick={() => props.changed(i)} component="span" classes={{ root: styles.thumbnailBox }}>
                            <Box component="div" classes={{ root: thumbnailImgBox }}>
                                <img src={`${props.general.imageUrl}${option.imageName}`} alt="Otis" style={{ height: '86px', width: '56px' }} />
                            </Box>
                            <Box component="div" classes={{ root: thumbnailLabel }}>{option.displayText}</Box>
                        </Box>
                    </Grid>
                )
            })
            inputElement = <Grid container>{inputElement}</Grid>
            break;
        case ('DropdownText'):
            inputElement = (
                <NativeSelect
                    style={{
                        margin: '10px 10px 0px 25px',
                        width: '40%',
                        float: 'left',
                    }}
                    id="demo-customized-select-native"
                    input={<ContactInputTxt />}
                    value={config.value}
                    onChange={props.changed}>
                    {
                        config.optionList.map((option, i) =>
                            <option key={i} value={i}>
                                {option.displayText}
                            </option>
                        )
                    }
                </NativeSelect>
            );
            break;
        default:
            inputElement = <InputTextbox
                classes={{ root: styles.textBox }}
                value={config.value}
                onChange={props.changed} />
    }

    return (
        <Box component="div" classes={{ root: styles.inputBox }}>
            <InputLabel classes={{ root: styles.label }} htmlFor="">
                <Box component="span" className={validationError}>
                    <Box component="span" classes={{ root: styles.qnNumber }}>{props.order}.</Box>
                    {config.displayText} <Box component="span" classes={{ root: styles.reqStar }}>*</Box>
                </Box>
            </InputLabel>
            {inputElement}
        </Box>
    );

};

export default filterInput;