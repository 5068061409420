import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const contactInputStyles = makeStyles((theme) => ({
    label: {
        color: '#031D43',
        fontSize: '12px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'flex-end',
        fontFamily: [
            'nunito-bold',
            'sans-serif'
        ].join(','),
    },
    valErr: {
        borderBottom: '1px solid red'
    },
    textBox: {
        height: '30px',
        marginTop: '7px'
    },
    reqStar: {
        color: 'red'
    },
    privacyPolicyReqStar: {
        color: 'red',
        marginLeft: '-12px',
        display: 'inline-block',
        fontWeight: 'bold',
        fontFamily: [
            'nunito-bold',
            'sans-serif'
        ].join(','),
    },
    checkboxLabel: {
        color: '#031D43',
        fontSize: '12px',
        fontFamily: [
            'nunito-regular',
            'sans-serif'
        ].join(','),
    },
    checkboxCont: {
        paddingRight: '5px'
    },
    checkboxPos: {
        marginTop: '-10px'
    },
    inputContainer: {
        [theme.breakpoints.down('xs')]: {
            paddingRight: '5px !important',
        }
    }
}));

export const ContactInputTxt = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: '15px',
        },
    },
    input: {
        width: '100%',
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #ced4da',
        fontSize: 14,
        padding: '8px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'nunito-regular',
            'sans-serif'
        ].join(','),
        '&:focus': {
            borderColor: '#041E42',
        },
    },
}))(InputBase);