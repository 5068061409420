import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";

import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";

import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { serviceListStyle } from "./CarouselView.theme";
import {
  WhiteButtonWithIcon,
  PinkButtonWithIcon
} from "./../../../helpers/js/appButtons";
import "./CarouselView.css";
import Slide from "./Slide";
import { getQueryParamsString } from './../../../helpers/js/getQueryParams';

class CarouselView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slide: 0
    };
  }

  setSlide(pos) {
    let slide = 0;
    if (pos === 0) {
      slide = 1;
    } else if (pos >= 0.4 && pos < 1) {
      slide = 2;
    } else if (pos === 1) {
      slide = 3;
    }
    if (this.state.slide !== slide && slide !== 0) {
      this.setState({ slide: slide });
    }
  }

  initizeSlider = () => {
    return {
      slidesPerView: "auto",
      spaceBetween: 10,
      //freeMode: true,
      // centeredSlides: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      on: {
        progress: pos => this.setSlide(pos)
      }
    };
  };

  goBackHandler = () => {
    const appendingQueryParams = getQueryParamsString();
    const { lang, country } = this.props.match.params;
    this.props.history.push(`/find-service/${lang}/${country}${appendingQueryParams}`);
  };
  selectServiceHandler = selected => {
    this.props.serviceOnSelected(selected);
  };

  render() {
    let params = this.initizeSlider();
    const serviceAttr = this.props.labels._services._serviceAttributes;
    const sro = this.props.serviceRecommendationOrder;

    return (
      <Fragment>
        <ThemeProvider theme={serviceListStyle}>
          <Typography variant="h4">
            {this.props.labels._services._recommendedServiceTitle}
          </Typography>

          <Swiper {...params}>
            {Object.entries(sro).map(([key, row], index) => (
              <Grid item xs={11} sm={11} key={key}>
                <TableContainer className={'tableContainer'}>
                  <Table className={index === 0 ? "serviceBgFirst" : "serviceBg"}>
                    <Slide
                      index={index}
                      service={this.props.services[sro[key]]}
                      serviceAttr={serviceAttr}
                    />
                  </Table>
                </TableContainer>
              </Grid>
            ))}
          </Swiper>
        </ThemeProvider>
        <Grid container>
          <Grid item xs={11} sm={6}>
            <Box xs={12} mt={3}>
              <WhiteButtonWithIcon
                onClick={this.goBackHandler}
                startIcon={
                  <Avatar
                    alt={this.props.labels._global._updateSelection}
                    className={"bottonImg"}
                    src={require("./../../../assets/images/o_config_up-arrow.svg")}
                  />
                }
              >
                {this.props.labels._global._updateSelection}
              </WhiteButtonWithIcon>
            </Box>
          </Grid>
          <Grid item xs={11} sm={5}>
            <Box xs={12} align="right" mt={2}>
              <PinkButtonWithIcon
                onClick={() => this.selectServiceHandler(sro[this.state.slide])}
                startIcon={
                  <Avatar
                    alt={this.props.labels._global._select}
                    className={"bottonImg"}
                    src={require("./../../../assets/images/o_config_down-arrow.svg")}
                  />
                }
              >
                {this.props.labels._global._select}
              </PinkButtonWithIcon>
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(CarouselView);
