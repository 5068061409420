import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import filterQuestionStyles from './FilterQuestions.theme'
import CheckValidity from './../../helpers/js/formValidation'
import FilterForm from './../../components/FilterForm/FilterForm';
import { getQueryParamsString } from './../../helpers/js/getQueryParams';

class FilterQuestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterForm: this.props.filterForm,
            formIsValid: false
        }
    }

    componentDidMount() {
        // Initiatizing slider default value
        if (this.state.filterForm && this.state.filterForm['p_9']) {
            const sliderSateVal = parseInt(this.state.filterForm['p_9'].value)
            const sliderVal = sliderSateVal > 0 ? sliderSateVal : 0;
            this.filterArrayInputChangedHandler(sliderVal, 'p_9')
        }
    }

    filterSubmitHandler = (event) => {
        event.preventDefault();
        const formData = {};
        const submittedFilterForm = {
            ...this.state.filterForm
        };
        for (let elementId in submittedFilterForm) {
            formData[elementId] = submittedFilterForm[elementId].value;
            submittedFilterForm[elementId].touched = true;
        }
        // console.log('submittedFilterForm', submittedFilterForm)
        if (this.state.formIsValid) {
            const appendingQueryParams = getQueryParamsString();
            this.props.submitted(submittedFilterForm);
            this.props.history.push(`/select-service/${this.props.params}${appendingQueryParams}`);
        } else {
            this.setState({ filterForm: submittedFilterForm });
        }
    }

    filterInputChangedHandler = (event, inputIdentifier) => {
        // console.log("!!!!!!!>>>", inputIdentifier)
        const updatedFilterForm = {
            ...this.state.filterForm
        };
        const updatedFormElement = {
            ...updatedFilterForm[inputIdentifier]
        };
        if ((inputIdentifier === 'p_2' || inputIdentifier === 'p_3') && event.target.value !== "") {
            updatedFormElement.value = (event.target.value > 0) ? event.target.value : updatedFormElement.value;
        } else {
            updatedFormElement.value = event.target.value;
        }
        updatedFormElement.valid = CheckValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedFilterForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedFilterForm) {
            formIsValid = updatedFilterForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ filterForm: updatedFilterForm, formIsValid: formIsValid });
    }

    filterArrayInputChangedHandler = (optionIndex, inputIdentifier) => {
        // console.log('filterArrayInputChangedHandler', optionIndex, inputIdentifier)
        const newEvent = {
            target: {
                value: optionIndex
            }
        }
        this.filterInputChangedHandler(newEvent, inputIdentifier);
    }

    resetFilter = () => {
        const resetProperty = {
            ...this.state.filterForm
        }
        for (let propertyIdentifier in resetProperty) {
            if (propertyIdentifier === 'p_9' || propertyIdentifier === 'p_34' || propertyIdentifier === 'p_35') {
                resetProperty[propertyIdentifier].value = 0;
            } else {
                resetProperty[propertyIdentifier].touched = false;
                resetProperty[propertyIdentifier].valid = false;
                resetProperty[propertyIdentifier].value = '';
            }
        }
        this.setState({ filterForm: resetProperty, formIsValid: false });
    }

    render() {
        //console.log('this.state >>', this.state)
        const { classes } = this.props;
        return (
            <React.Fragment>
                {
                    this.props.appLoader === 'off' ?
                        <Container className={classes.container}>
                            <FilterForm
                                filterForm={this.state.filterForm}
                                general={this.props.general}
                                formIsValid={this.state.formIsValid}
                                filterSubmitHandler={this.filterSubmitHandler}
                                filterArrayInputChangedHandler={this.filterArrayInputChangedHandler}
                                filterInputChangedHandler={this.filterInputChangedHandler}
                                resetFilter={this.resetFilter}
                                labels={this.props.labels}
                            />
                        </Container>
                        :
                        <LinearProgress />
                }
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(filterQuestionStyles, { withTheme: true })(FilterQuestions));