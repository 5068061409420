import RulesEngine from './RulesEngine'
    const routingLogic = (parameters) => {
    const params = Object.assign({}, parameters)
    // console.log("RoutingLogic Params :>", params )

    //-------------------------------------//
    // Number of Units() dont have score
    //-------------------------------------//
    params.p_3 = 0
    
    //--------------------------------------------------//
    // To calculate score for Building Stops - p_2
    // Formula =IF(C10=2,-1,IF(C10>4,1,IF(C10>14,2,0)))
    // Input: User Input value fron filter form
    //--------------------------------------------------//
    const buildingStopsScore_LOGIC = { 
        "if" : [
            {">": [{"var":"p_2"}, 14] }, 2,
            {">": [{"var":"p_2"}, 4] }, 1,
            {">=": [{"var":"p_2"}, 3] }, 0,
            -1
        ]
    }
    const buildingStopsScore_ip = { "p_2": parseInt(params.p_2)}
    params.p_2 = RulesEngine.apply(buildingStopsScore_LOGIC, buildingStopsScore_ip);
    // console.log('buildingStopsScore_LOGIC>>>>>>', params.p_2);

    //--------------------------------//
    // $D$41 => N SCORE TRIGGER - UP
    // Value = 6 (Constant)
    //--------------------------------//
    const N_SCORE_TRIGGER_UP = params.p_29 ? parseInt(params.p_29) : 6;
    // console.log('N_SCORE_TRIGGER_UP>>>>>>', N_SCORE_TRIGGER_UP);

    //--------------------------------//
    // $D$42 => N SCORE TRIGGER - DOWN
    // Value = 6 (Constant)
    //--------------------------------//
    const N_SCORE_TRIGGER_DOWN = params.p_30 ? parseInt(params.p_30) : 0;
    // console.log('N_SCORE_TRIGGER_DOWN>>>>>>', N_SCORE_TRIGGER_DOWN);

    //--------------------------------//
    // $D$43 => F SCORE TRIGGER
    // Value = 7 (Constant)
    //--------------------------------//
    const F_SCORE_TRIGGER = params.p_31 ? parseInt(params.p_31) : 7;
    // console.log('F_SCORE_TRIGGER>>>>>>', F_SCORE_TRIGGER);

    //--------------------------------//
    // D38 => N_SCORE
    // Equation =SUM(D9:D29)-D18  [D18 is params.p_6]
    // This is the sum of the SCORES. 
    // Therefore, the scoreless properties were excluded from the list.
    //--------------------------------//
    const N_SCORE_INPUTS = [params.p_1, params.p_2, params.p_3, params.p_4, params.p_7, params.p_8, params.p_9, params.p_10, params.p_11, params.p_12, params.p_13, params.p_14, params.p_15]
    const N_SCORE = N_SCORE_INPUTS.reduce((a, b) => parseInt(a) + parseInt(b), 0)
    // console.log('N_SCORE>>>>>>', N_SCORE);
    // console.log('Building Vertical  >>', params.p_1, 'Elevator Use Frequency>>>>>>', params.p_9)
    
    //--------------------------------//
    // D39 => F_SCORE
    // Equation =SUM(D31:D36,D18)
    //--------------------------------//
    const F_SCORE_INPUTS = [params.p_16, params.p_17, params.p_18, params.p_19, params.p_20, params.p_21, params.p_6]
    const F_SCORE = F_SCORE_INPUTS.reduce((x, y) => parseInt(x) + parseInt(y), 0)
    // console.log('F_SCORE>>>>>>', F_SCORE);

    // ***Input Details***
    // D38 => N_SCORE => Eqn
    // $D$41 => N SCORE TRIGGER - UP => const
    // D42 => N SCORE TRIGGER - DOWN => const
    // C16 => Negotiation Type => default text
    const N_SCORE_CONTRACT_LOGIC = { 
        "if" : [
            {">": [{"var":"D38"}, {"var":"D41"}] }, "OTIS ACCELERATE",
            {"and": [
                {"<" : [{"var":"D38"}, {"var":"D42"}]},
                {"or": [
                    {"==" : [{"var":"C16"}, "Recapture"]},
                    {"==" : [{"var":"C16"}, "Conversion"]}
                ]},
            ]},"OTIS SERVICE", "OTIS MAINTENANCE",
        ]
    }
    // params.p_5 = "Recapture"
    const NSC_ip = { "D38": N_SCORE, "D41": N_SCORE_TRIGGER_UP, "C16": params.p_5, "D42": N_SCORE_TRIGGER_DOWN }
    const N_SCORE_CONTRACT = RulesEngine.apply(N_SCORE_CONTRACT_LOGIC, NSC_ip);
    // console.log('N_SCORE_CONTRACT >>>', N_SCORE_CONTRACT, NSC_ip)

    // ***Input Details***
    // D39 => F_SCORE => Eqn
    // D43 => F_SCORE_TRIGGER => Const
    const F_SCORE_CONTRACT_LOGIC = { 
        "if" : [
            {">": [{"var":"D39"}, {"var":"D43"}] }, "OTIS ACCELERATE"
            ,"OTIS MAINTENANCE"
        ]
    }
    const FSC_ip = { "D39": F_SCORE, "D43": F_SCORE_TRIGGER };
    const F_SCORE_CONTRACT = RulesEngine.apply(F_SCORE_CONTRACT_LOGIC, FSC_ip);
    // console.log('F_SCORE_CONTRACT >>>', F_SCORE_CONTRACT, FSC_ip)

    // ***Input Details***
    // N_SCORE_CONTRACT => D45
    // F_SCORE_CONTRACT => D46
    // Sample Input-1: {"D45": "s_1"}
    // Sample Input-2: {"D45": "s_2", "D46": "s_2"}
    const recommendedOffering_LOGIC = { 
        "if" : [
            {"==": [{"var":"D45"}, "OTIS SERVICE"] }, "OTIS SERVICE",
            {"and": [
                {"==" : [{"var":"D45"}, "OTIS ACCELERATE"]},
                {"==" : [{"var":"D46"}, "OTIS COMPLETE"]}
            ]},"OTIS COMPLETE",
            {"==": [{"var":"D45"}, "OTIS ACCELERATE"] }, "OTIS ACCELERATE",
            "OTIS MAINTENANCE"
        ]
    }
    const RO_ip = { "D45": N_SCORE_CONTRACT, "D46": F_SCORE_CONTRACT };
    const excelRecommendedOffering = RulesEngine.apply(recommendedOffering_LOGIC, RO_ip);
    // console.log('excelRecommendedOffering >>>', excelRecommendedOffering, RO_ip)


    // ################################# IMP Note Starts ###################################
    //  *** Secondary and Tertiary Offerings Excel logics need to be replacing because ****
    //  *** there are only 3 services now, and one service always in 3rd offering ************
    // #######################################################################################
    // ***Input Details***
    // H9 => Output of recommendedOffering
    // Sample Input: {"H9": "s_2"}
    // const secondaryOffering_LOGIC = {
    //     "if" : [
    //         {"==": [{"var":"H9"}, "OTIS SERVICE"] }, "OTIS MAINTENANCE",
    //         {"==": [{"var":"H9"}, "OTIS ACCELERATE"] }, "OTIS COMPLETE",
    //         {"==": [{"var":"H9"}, "OTIS COMPLETE"] }, "OTIS COMPLETE",
    //         {"==": [{"var":"H9"}, "OTIS MAINTENANCE"] }, "OTIS ACCELERATE"
    //     ]
    // }
    // const SO_ip = { "H9": recommendedOffering };
    // const excelSecondaryOffering = RulesEngine.apply(secondaryOffering_LOGIC, SO_ip);
    // console.log('secondaryOffering >>>', secondaryOffering, SO_ip)

    // ***Input Details***
    // H9 => Output of recommendedOffering
    // const tertiaryOffering_LOGIC  = { 
    //     "if" : [
    //         {"==": [{"var":"H9"}, "OTIS SERVICE"] }, "OTIS SERVICE",
    //         {"==": [{"var":"H9"}, "OTIS ACCELERATE"] }, "OTIS MAINTENANCE",
    //         {"==": [{"var":"H9"}, "OTIS COMPLETE"] }, "OTIS ACCELERATE",
    //         {"==": [{"var":"H9"}, "OTIS MAINTENANCE"] }, "OTIS SERVICE PLUS"
    //     ]
    // }
    // const TO_ip = { "H9": recommendedOffering };
    // const excelTertiaryOffering = RulesEngine.apply(tertiaryOffering_LOGIC, TO_ip);
    // console.log('tertiaryOffering >>>', tertiaryOffering, TO_ip)
    // ################################# IMP Note Ends ###################################

    // ##################################################################################
    // #  Service-ID    Generic Name	     Excel	            experlogix      
    // ----------------------------------------------------------------------------------
    // 1	s_1         Package 1 - High     OTIS ACCELERATE	 Otis Maintenance
    // 2	s_1         Package 1 - High     OTIS COMPLETE	     Otis Maintenance
    // 3	s_1         Package 1 - High     OTIS MAINTENANCE	 Otis Maintenance
    // 4	s_2         Package 2 - Medium   OTIS SERVICE PLUS	 Otis Service
    // 5    s_3     	Package 3 - Low      OTIS SERVICE	     Otis Lubricate & Survey
    // ###################################################################################
    // Special case as per mail communication: OTIS SERVICE(In Excel) is Otis Service(In experlogix)
    const latestServiceMapping = {
        "if" : [            
            {"==": [{"var":"excelService"}, "OTIS ACCELERATE"] }, "s_1",
            {"==": [{"var":"excelService"}, "OTIS COMPLETE"] }, "s_1",
            {"==": [{"var":"excelService"}, "OTIS MAINTENANCE"] }, "s_1",
            {"==": [{"var":"excelService"}, "OTIS SERVICE PLUS"] }, "s_2",
            {"==": [{"var":"excelService"}, "OTIS SERVICE"] }, "s_2"
        ]
    }
    const excelServiceName = { 'excelService': excelRecommendedOffering };
    const latestRecommendedOffering = RulesEngine.apply(latestServiceMapping, excelServiceName);
    const latestSecondaryOffering = (latestRecommendedOffering === 's_1') ? 's_2' : 's_1';
    const latestTertiaryOffering = 's_3';
    return {
        "1": latestRecommendedOffering,
        "2": latestSecondaryOffering,
        "3": latestTertiaryOffering
      }


//--------------------------------//
// D38 => N_SCORE
// Equation =SUM(D9:D29)-D18
//--------------------------------//
// $D$41 => N SCORE TRIGGER - UP
// Value = 6 (Constant)
//--------------------------------//
// C16 => Negotiation Type
//  value is strig
//--------------------------------//
// D39 => F_SCORE
// Equation =SUM(D31:D36,D18)
//--------------------------------//
// $D$43 => F SCORE TRIGGER
// Value = 7 (Constant)
//--------------------------------//


};

export default routingLogic