import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import masterData from './helpers/json/masterData.json'
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import FilterQuestions from './containers/FilterQuestions/FilterQuestions';
import FilterResults from './containers/FilterResults/FilterResults';
import ContactUs from './containers/Contact/Contact';
import ContactFormSubmitted from './components/ContactForm/ContactFormSubmitted'
import Error from './components/Error/Error'
import routingLogic from './helpers/js/routingLogic'
import ScrollToTop from './helpers/js/scrollToTop'
import './assets/css/fonts.css'
import './App.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = masterData;
  }

  componentDidMount() {   
    // To get url parameters
    let apiParams = ''
    this.urlParams = ''
    this.country = ''
    this.lang = ''
    this.appUrl = ''
    const path = window.location;
    if(path && path.pathname){
      let pathArray = path.pathname.split('/');
      apiParams = pathArray[pathArray.length - 2] + '-' + pathArray[pathArray.length - 1]
      this.urlParams = pathArray[pathArray.length - 2] + '/' + pathArray[pathArray.length - 1]
      this.country = pathArray[pathArray.length - 1]
      this.lang = pathArray[pathArray.length - 2]
      this.appUrl = path
    }
    //console.log(this.state);
    axios.get(`${process.env.REACT_APP_API_URL}/signatureService?countryLangCode=${apiParams}`)
      .then(res => {
        this.initializeDefaultValues(res.data.data);
      })
      .catch(err =>{
        this.setState({appLoader: 'error'});
        // console.log(this.state);
        //console.log('error >>', err)
      })      
      
  }

  initializeDefaultValues(data) {
    const newState = Object.assign(data, { appLoader: 'off' })
    const keys = ['p_9', 'p_34', 'p_35']
    keys.forEach(key=>{
      newState.properties[key].valid = true;
      newState.properties[key].touched = true;
      newState.properties[key].value = 0;
    });
    this.setState(newState);
  }

  saveFilterInputsHandler = (formData) => {
    const filterInput = {};
    for (let elementId in formData) {
      let val = formData[elementId].value;
      if(formData[elementId].propertyType === 'TextBox') {
        filterInput[elementId] = val;
      } else {
        filterInput[elementId] = formData[elementId].optionList[val]['score']
      }      
    }
    const defProp = this.state.defaultProperties;
    const logicInput = Object.assign(filterInput, defProp)
    const SRO = routingLogic(logicInput);
    // console.log("SRO>>>>>>>>>", SRO)
    this.setState({properties: formData, serviceRecommendationOrder: SRO});   
    // console.log("logicInput>>>>>>>>>", logicInput) 
    // console.log("###########################", this.state)
  }

  setSelectedServiceHandler = (serviceId) => {
    this.setState({userSelectedService: serviceId});
  }

  render () {    
    const { services, properties, labels, appLoader, general, serviceRecommendationOrder, userSelectedService, contactUs } = this.state;
    const homeLabels = {
      '_home': labels._home, 
      '_global': labels._global
    }
    const questionsLabels = {
      '_filter': labels._serviceFilter, 
      '_global': labels._global
    }
    const servicesLabels = {
      '_services': labels._services, 
      '_global': labels._global
    }
    const contactLabels = {
      '_contact': labels._contactUs, 
      '_global': labels._global
    }
    return (
      <div className="App">
        <CssBaseline />
          <Router>
            <ScrollToTop />
            <Layout labels={labels._global} params={this.urlParams}>          
              {this.state.appLoader === "error" ? <Redirect to="/Error" /> : null}
              <Switch>
                <Route path="/contact-us/:type?/:lang/:country"  exact
                  component={() => <ContactUs labels={contactLabels} 
                                        contactUs={contactUs}
                                        properties={properties}
                                        services={services}
                                        serviceRecommendationOrder={serviceRecommendationOrder}
                                        userSelectedService={userSelectedService}
                                        appLoader={appLoader}
                                        country_={this.country}
                                        lang = {this.lang}
                                        appUrl = {this.appUrl}
                                        params={this.urlParams} />} />
                <Route path="/find-service/:lang/:country"  exact
                  component={() => <FilterQuestions labels={questionsLabels} 
                                        filterForm={properties}
                                        general={general}
                                        submitted={this.saveFilterInputsHandler}
                                        appLoader={appLoader}
                                        params={this.urlParams}  />} />
                <Route path="/select-service/:lang/:country"  exact
                  component={() => <FilterResults labels={servicesLabels} 
                                        services={services}
                                        appLoader={appLoader}
                                        serviceRecommendationOrder={serviceRecommendationOrder}
                                        onSelected={this.setSelectedServiceHandler} />} />
                <Route path="/thank-you/:lang/:country" exact 
                  component={() => <ContactFormSubmitted labels={labels._thankYou} />} />
                <Route path="/:lang/:country" exact 
                  component={() => <Home labels={homeLabels} appLoader={appLoader} />} />
                <Route path="/Error" exact component={Error} />
                <Redirect to="/en/us" />
              </Switch>                    
            </Layout>
          </Router>
      </div>
    );
  }
}

export default App;
