import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { contactInputStyles, ContactInputTxt } from './ContactInput.theme'
// import Button from '@material-ui/core/Button';
import './ContactInput.css';

const contactInput = (props) => {
    // console.log("props", props)
    const classes = {};
    const styles = contactInputStyles();
    let inputElement = null;
    const inputClasses = [classes.InputElement];
    const config = props.config;
    if (!config.valid && config.validation && config.touched) {
        inputClasses.push(classes.Invalid);
    }
    let validationError = null;
    if (!config.valid && config.touched) {
        validationError = styles.valErr;
    }
    switch (config.elementType) {
        case ('dropdown'):
            inputElement = <NativeSelect
                style={{ marginTop: 5 }}
                fullWidth
                id="demo-customized-select-native"
                value={config.value}
                onChange={props.changed}
                input={<ContactInputTxt />}
            >
                {
                    config.elementConfig.options.map((option, i) =>
                        <option key={i} value={option.value}>
                            {props.labels._contact[option.value]}
                        </option>
                    )
                }
            </NativeSelect>
            break;
        case ('dropdownAPI'):
            inputElement = (
                <NativeSelect
                    style={{ marginTop: 5 }}
                    fullWidth
                    id="demo-customized-select-native"
                    value={config.value}
                    onChange={props.changed}
                    input={<ContactInputTxt />}
                >
                    {
                        config.elementConfig.options.map((option, i) =>
                            <option key={i} value={option.name}>
                                {option.displayText}
                            </option>
                        )
                    }
                </NativeSelect>
            );
            break;
        case ('checkbox'):
            inputElement = <FormControlLabel
                value="true" classes={{ label: styles.checkboxLabel, root: 'custom-check' }}
                control={<Checkbox onChange={props.changed} classes={{ root: styles.checkboxCont }} size="small" color="primary" />}
                label={props.labels._contact[config.displayTextKey]}
                labelPlacement="end"
                className={styles.checkboxPos}
            />
            break;
        case ('checkboxPolicy'):
            let policy = props.labels._contact;
            let label = <p className={validationError}>{policy._agreeto} <a href={policy._privacyPolicyUrl} className="privacyPolicyUrl" target="_blank" rel="noopener noreferrer" >{policy._privacyPolicyKey}</a> {policy._privacypolicysuffix}</p>
            inputElement = <FormControlLabel
                value="true" classes={{ label: styles.checkboxLabel }}
                control={<Checkbox onChange={props.changed} classes={{ root: styles.checkboxCont }} size="small" color="primary" />}
                label={label}
                labelPlacement="end"
                className={styles.checkboxPos}
            />
            break;
        default:
            inputElement = <ContactInputTxt
                fullWidth={true}
                classes={{ root: styles.textBox }}
                value={config.value}
                inputProps={{ autoComplete: 'off' }}
                onChange={props.changed} />
    }

    return (
        <Grid item xs={12} sm={config.inputWidth} my={0.5} classes={{ root: styles.inputContainer }}>
            <InputLabel classes={{ root: styles.label }} htmlFor="" className={`${props.propName}-${props.lang}-${props.country}`}>
                {(config.elementType !== 'checkbox' && config.elementType !== "checkboxPolicy") ?
                    <Box component="span" className={validationError}>
                        {props.labels._contact[config.displayTextKey]} <Box component="span" classes={{ root: styles.reqStar }}>*</Box>
                    </Box>
                    : null}
            </InputLabel>
            <Grid item sm={12} className={(config.elementType === 'checkboxPolicy') ? 'checkboxPolicy' : ''}>
                {inputElement}
                {(config.elementType === "checkboxPolicy") ?
                    <Box component="span" classes={{ root: styles.privacyPolicyReqStar }}><sup>*</sup></Box>
                    : null}
            </Grid>
        </Grid>
    );

};

export default contactInput;