import { makeStyles } from "@material-ui/core/styles";

export const rowStyles = makeStyles(theme => ({
  tCell: {
    verticalAlign: "top",
    padding: "10px 15px !important"
  }
  // tr: {
  //     background: '#F5F6F7',
  //     borderBottom: '1px solid #dee2e6'
  // },
  // firstRow: {

  // },
  // contentTd: {
  //     width: '25%',
  //     padding: '10px 15px'
  // },
  // noContentTd: {
  //     padding: '0 15px',
  //     background: 'none',
  //     borderSpacing: '0',
  // },
  // recommended: {
  //     background: '#cba051',
  // }
}));
