import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    desk: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
      [theme.breakpoints.only('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    tab: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
      [theme.breakpoints.only('sm')]: {
        display: 'block'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    mob: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
      [theme.breakpoints.only('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    }
}));
  