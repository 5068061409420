import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    // container: {
    //     padding: '20px 68px 20px 68px',
    //     [theme.breakpoints.down('sm')]: {
    //         padding: '20px 45px 20px 45px'
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         padding: '20px 20px 20px 20px'
    //     },
    // }
    container: {
        maxWidth: '940px', 
        padding: '15px 10px',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '15px 5px'
        },
    }
}));