import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const PinkButtonWithIcon = withStyles(theme => ({
  root: {
    backgroundColor: "#F65375",
    fontSize: "14px",
    padding: "10px 20px",
    borderRadius: "0px",
    boxShadow: "none",
    textTransform: "none",
    lineHeight: "normal",
    color: "#FFFFFF",
    // marginTop: '20px',
    fontFamily: ["nunito-regular", "sans-serif"].join(","),
    "&:hover": {
      backgroundColor: "#F65375",
      borderColor: "none",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "none"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.1rem #F65375"
    },
    "& .MuiButton-startIcon": {
      marginRight: "2px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}))(Button);

export const WhiteButtonWithIcon = withStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    // marginRight: '15px',
    // marginTop: '20px',
    borderRadius: "0px",
    boxShadow: "none",
    textTransform: "none",
    borderColor: "#F65375",
    border: "1px solid #F65375",
    color: "#F65375",
    lineHeight: "normal",
    fontFamily: ["nunito-regular", "sans-serif"].join(","),
    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      border: "1px solid #F65375"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#FFFFFF",
      border: "1px solid #F65375"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.1rem #F65375"
    },
    "& .MuiButton-startIcon": {
      marginRight: "2px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}))(Button);

export const GreyButtonWithIcon = withStyles(theme => ({
  root: {
    backgroundColor: "#616161",
    fontSize: "14px",
    padding: "10px 20px",
    borderRadius: "0px",
    boxShadow: "none",
    textTransform: "none",
    lineHeight: "normal",
    color: "#FFFFFF",
    // marginTop: '20px',
    fontFamily: ["nunito-regular", "sans-serif"].join(","),
    "&:hover": {
      backgroundColor: "#616161",
      borderColor: "none",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "none"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.1rem #F65375"
    },
    "& .MuiButton-startIcon": {
      marginRight: "2px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}))(Button);

export const TransparentButton = withStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    fontSize: "12px",
    padding: "0",
    borderRadius: "0px",
    boxShadow: "none",
    textTransform: "none",
    lineHeight: "normal",
    color: "blue",
    // marginTop: '20px',
    fontFamily: ["nunito-regular", "sans-serif"].join(","),
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "none",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "none"
    },
    "&:focus": {
      boxShadow: "none"
    },
    "& .MuiButton-startIcon": {
      marginRight: "2px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}))(Button);
