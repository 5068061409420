import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';


export const filterInputStyles = makeStyles((theme) => ({
    inputBox: {
        padding: '5px 0px 20px 0px', 
        width: '100%',
        float: 'left'
    },
    label: {
        color: '#031D43',
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: [
            'nunito-bold',
            'sans-serif'
          ].join(','),
    },
    valErr: {
      borderBottom: '1px solid red'
    },
    textBoxSM: {
        width: '75px', 
        height: '30px', 
        marginTop: '10px',
        color: '#F65375',
        marginLeft: '25px',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '0px'
      }
    },
    textBoxSMinput: {
        textAlign: 'center', 
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
          fontSize: '16px !important'
        }
    },
    textBox: {
        width: '250px', 
        height: '30px', 
        marginTop: '10px'
    },
    thumbnailBox: {
        float: 'left',
        paddingTop: '4px',
        paddingRight: '8px',
        '&:hover': {
          '& $thumbnailImgBox': {
            border: '2px solid #F65375'
          },
          '& $thumbnailLabel': {
            color: '#F65375'
          }
        }
    },
    thumbnailImgBox: {
        border: '2px solid #E0E0E0', 
        //marginRight: '9px', 
        marginTop: '5px', 
        cursor: 'pointer',
        lineHeight: 'normal',
        height: '90px',
        width: '60px'
    },
    thumbnailImgBoxSelected: {
        border: '2px solid #F65375 !important'
    },
    thumbnailLabel: {
        minWidth: '65px', 
        maxWidth: '65px',
        cursor: 'pointer', 
        wordBreak: 'break-word', 
        fontSize: '12px', 
        textAlign:'center',
        color: '#031D43',
        fontWeight: 'bold',
        paddingTop: '5px',
        height: 'auto',
        fontFamily: [
            'nunito-bold',
            'sans-serif'
          ].join(','),

    },
    thumbnailLabelSelected: {
        color: '#F65375'
    },
    qnNumber: {
      marginRight: '7px'
    },
    reqStar: {
      color: 'red'
    }
}));

export const InputTextbox = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: '12px',
      },
    },
    input: {
      //borderRadius: 4,
      position: 'relative',
      backgroundColor: 'white',
      border: '1px solid #ced4da',
      fontSize: 14,
      width: '100%',
      padding: '8px 10px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'nunito-regular',
        'sans-serif'
      ].join(','),
      '&:focus': {
        borderColor: '#041E42',
      },
    },
  }))(InputBase);

  export const ContactInputTxt = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: '15px'
      },
    },
    input: {
      width: '100%',
      position: 'relative',
      fontSize: '14px !important',
      fontWeight: 600,
      backgroundColor: 'white',
      border: '1px solid #ced4da',
      color: '#F65375',
      padding: '8px 10px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'nunito-regular',
        'sans-serif'
      ].join(','),
      '&:focus': {
        borderColor: '#041E42',
      },
    },
  }))(InputBase);