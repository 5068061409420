import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";

export const serviceListStyle = createMuiTheme({
  typography: {
    fontFamily: "grifo-m-bold",
    fontWeight: "bold",
    h4: {
      fontSize: "20px",
      color: "#031D43"
    },
    h3: {
      color: "#FFFFFF",
      fontSize: "18px",
      lineHeight: 'normal',
      textAlign: 'center'
    }
  }
});

export const mobileViewStyles = makeStyles(theme => ({
  tCell: {
    verticalAlign: "top",
    padding: "10px 15px !important"
  },
  serviceHeader: {
    background: "#616161",
    color: "white"
  },
  serviceHeaderFirst: {
    background: "#F65375",
    color: "white"
  },
  serviceBg: {
    background: "#F4F5F7"
  },
  serviceBgFirst: {
    background: "#F4AEBD"
  },
  serviceTitle: {
    color: "white"
  }
}));

export const WhiteButton = withStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    fontSize: 14,
    padding: "10px 20px",
    borderRadius: "0px",
    boxShadow: "none",
    textTransform: "none",
    borderColor: "#F65375",
    border: "1px solid #F65375",
    color: "#F65375",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      border: "1px solid #F65375"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#FFFFFF",
      border: "1px solid #F65375"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.1rem #F65375"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}))(Button);

export const PinkButton = withStyles(theme => ({
  root: {
    backgroundColor: "#F65375",
    fontSize: 14,
    padding: "10px 20px",
    borderRadius: "0px",
    boxShadow: "none",
    textTransform: "none",
    color: "#FFFFFF",
    fontFamily: ["nunito-regular", "sans-serif"].join(","),
    "&:hover": {
      backgroundColor: "#F65375",
      borderColor: "none",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "none"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.1rem #F65375"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}))(Button);
