import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { getStartStyles } from './../GetStarted/GetStarted.theme'

export default (props) => {
    const classes = getStartStyles();
    return (
        <React.Fragment>
            <Grid item md={7} xs={12}>
                <Container classes={{root: classes.container}}>
                    <Typography variant="h5" classes={{root: classes.h5Bold}}>
                        {props.labels._thankyouTitle}
                    </Typography>
                    <Typography variant="h5" classes={{root: classes.h5Bold}}>
                        {props.labels._thankyouDesc}
                    </Typography>
                </Container>    
            </Grid>
        </React.Fragment>
    )
}