import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ErrorStyles from './Error.theme'

export default (props) => {
    const classes = ErrorStyles();
    return (
        <React.Fragment>
            <Container classes={{root: classes.container}}>
                <Typography variant="h3" classes={{root: classes.h3}}>
                        Oops!
                </Typography>
                <Typography variant="h5" classes={{root: classes.h5Bold}}>
                    Looks like something went wrong.
                </Typography>
            </Container>
        </React.Fragment>
    )
}