import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const BlueButton = withStyles((theme) => ({
    root: {
        border: '1px solid #F65375',
        background: 'transparent',
        fontFamily: [
            'nunito-regular',
            'sans-serif'
          ].join(','),
        fontSize: 14,
        padding: '10px 20px 10px 20px',
        lineHeight: 'normal',
        borderRadius: '0px',
        boxShadow: 'none',
        textTransform: 'none', 
        color: '#FFFFFF',  
        '&:hover': {
            background: 'transparent',
            border: '1px solid #F65375',
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'none',
            borderColor: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem #F65375',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 12
        }
    },
}))(Button);

export const headerStyles = makeStyles((theme) => ({
    root: {
        background: '#031D43',
        // padding: '20px 10px 20px 10px',
        position: 'static',
        [theme.breakpoints.down('xs')]: {
          position: 'fixed',
          top: 0,
          left: 'auto',
          right:0,
      }    
    },
    conRoot: { 
        maxWidth: '940px',
        padding: 0,     
        // [theme.breakpoints.up('md')]: {
        //     padding: '0px'
        // },
        // [theme.breakpoints.down('sm')]: {            
        //     padding: '0px'
        // },
        // [theme.breakpoints.down('xs')]: {
        //     padding: 0
        // },
    },
    toolBar: {
        padding: '20px 10px 20px 10px',
        [theme.breakpoints.down('sm')]: {            
            padding: '20px 20px 20px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 5px 20px 5px',
        }
    },
    iconCss: {
        [theme.breakpoints.down('sm')]: {
            width: '120px'
        },
        [theme.breakpoints.only('md')]: {
            width: '120px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '200px',
            height: '64px'
        }
    },
    offsetCss: {
        [theme.breakpoints.down('xs')]: theme.mixins.toolbar,
        marginTop:'22px',
    },
    logoImg:{
        lineHeight: '0'
    }
  }));
