import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { rowStyles } from "./RowElement.theme";
// import './RowElement.css'

export default props => {
  const classes = rowStyles();
  const tCell = {
    root: classes.tCell
  };
  const sR = props.serviceRecommendation;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell classes={tCell}>{props.optionTitle}</TableCell>
        <TableCell
          className={sR === "s_1" ? "content recommendedService" : "content"}
          classes={tCell}
          align={props.align ? props.align : "left"}
        >
          {props.serviceOrderOne}
        </TableCell>
        <TableCell className="no-content"></TableCell>
        <TableCell
          className={sR === "s_2" ? "content recommendedService" : "content"}
          classes={tCell}
          align={props.align ? props.align : "left"}
        >
          {props.serviceOrderTwo}
        </TableCell>
        <TableCell className="no-content"></TableCell>
        <TableCell
          className={sR === "s_3" ? "content recommendedService" : "content"}
          classes={tCell}
          align={props.align ? props.align : "left"}
        >
          {props.serviceOrderThree}
        </TableCell>

        <TableCell className="no-content"></TableCell>
      </TableRow>
    </React.Fragment>
  );
};
