export default (theme) => ({
    container: {
        maxWidth: '940px', 
        padding: '20px 10px 20px 10px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px 20px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 5px 20px 5px'
        },
    },
    globalLoader: {
        position: 'relative', 
        width: '100%', 
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed'
        }
    }
  })