import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import "./CarouselView.css";

export default props => {
  const service = props.service;
  const serviceAttr = props.serviceAttr;
  return (
    <React.Fragment>
      <TableHead
        className={props.index === 0 ? "serviceHeaderFirst" : "serviceHeader"}
      >
        <TableRow>
          <TableCell align="left" colSpan={2}>
            <Typography variant="h3">{service.displayText}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(serviceAttr).map(([key, val], index) => (
          <TableRow key={index}>
            <TableCell align="left" style={{ width: "45%" }}>
              {val}
            </TableCell>
            <TableCell align="left" style={{ width: "55%" }}>
              {service.attributes[key]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </React.Fragment>
  );
};
